import { moduleDelays } from "./utils";
import { $ } from "@utils/dom";
import { on, off } from "@utils/listener";

const SELECTOR = "[data-site-transition]";
const ExIT_CLASSNAME = "--exit";
const ENTER_CLASSNAME = "--enter";

class SiteTransition {
  constructor() {
    this.el = $(SELECTOR);

    this._exitPromise = null;
    this._enterPromise = null;

    this._onExitCompleted = this._onExitCompleted.bind(this);
    this._onEnterCompleted = this._onEnterCompleted.bind(this);
  }

  exit() {
    this.el.classList.remove("pointer-events-none");

    return new Promise(resolve => {
      this._exitPromise = resolve;

      on(this.el, 'animationend', this._onExitCompleted);

      this.el.classList.add(ExIT_CLASSNAME);
      this.el.classList.remove("visibility-hidden");
    });
  }

  entering() { moduleDelays(350, 350); }

  enter() {
    return new Promise(resolve => {
      this._enterPromise = resolve;

      on(this.el, 'animationend', this._onEnterCompleted);
      this.el.classList.replace(ExIT_CLASSNAME, ENTER_CLASSNAME);
    });
  }

  _onExitCompleted() {
    off(this.el, 'animationend', this._onExitCompleted);

    this._exitPromise();
    this._exitPromise = null;
  }
  _onEnterCompleted() {
    off(this.el, 'animationend', this._onEnterCompleted);
    
    this.el.classList.add("visibility-hidden", "pointer-events-none");
    this.el.classList.remove(ExIT_CLASSNAME, ENTER_CLASSNAME);

    this._enterPromise();
    this._enterPromise = null;
  }
}

export default SiteTransition;
