import { on, off } from "@utils/listener";
import Throttle from "@utils/throttle";

const DEFAULT_LEAVE_DURATION = 500;
const MOUSEENTER_CLASSNAME = '--js-mouse-enter';
const MOUSELEAVE_CLASSNAME = '--js-mouse-leaving';

class HoverButton {
  constructor(el) {
    this.el = el;

    this.timeout = null;

    this._isHover = false;
    this._leavingDuration = parseInt( getComputedStyle(this.el).getPropertyValue('--hover-leaving-duration') || DEFAULT_LEAVE_DURATION );

    this._onMouseEnter = this._onMouseEnter.bind(this);
    this._onMouseLeave = this._onMouseLeave.bind(this);
    this._onMouseIntent = this._onMouseIntent.bind(this);
    this._onLeaveCompleted = this._onLeaveCompleted.bind(this);

    this.init();
  }

  init() {
    this._bindEvents();

    this.throttler = new Throttle({cb: this._onMouseIntent, delay: 50, onlyAtEnd: true});
  }
  destroy() {
    this._unbindEvents();

    if(this.throttler) this.throttler.dispose();

    if (this.timeout) clearTimeout(this.timeout);

    this.el = null;
    this.throttler = null;

    this.timeout = null;

    this._isHover = null;
    this._leavingDuration = null;

    this._onMouseEnter = null;
    this._onMouseLeave = null;
    this._onMouseIntent = null;
    this._onLeaveCompleted = null;
  }

  _bindEvents() {
    on(this.el, 'mouseenter', this._onMouseEnter);
    on(this.el, 'mouseleave', this._onMouseLeave);
  }
  _unbindEvents() {
    off(this.el, 'mouseenter', this._onMouseEnter);
    off(this.el, 'mouseleave', this._onMouseLeave);
  }

  _onMouseEnter() { 
    this._isHover = true;

    this.throttler.init(); 
  }
  _onMouseLeave() {
    this._isHover = false;

    this.throttler.init();
  }

  _onMouseIntent(){
    if (this._isHover) {
      // switch classname is a DOM mutation
      if( !this.el.classList.contains(MOUSELEAVE_CLASSNAME) ) this.el.classList.add(MOUSEENTER_CLASSNAME);
    } else {
      // switch classname is a DOM mutation
      if( this.el.classList.contains(MOUSEENTER_CLASSNAME) ) this.el.classList.replace(MOUSEENTER_CLASSNAME, MOUSELEAVE_CLASSNAME);
      else this.el.classList.add(MOUSELEAVE_CLASSNAME);
      
      this.timeout = setTimeout(() => {
        this._onLeaveCompleted();
      }, this._leavingDuration);
    }
  }

  _onLeaveCompleted(){
    this.el.classList.remove(MOUSELEAVE_CLASSNAME);

    if (this._isHover) {
      setTimeout(() => {
        this.el.classList.add(MOUSEENTER_CLASSNAME);
      }, 20);
    }
  }
}
export default HoverButton;

