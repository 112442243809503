export default [
  //"modules/accordions",
  //"modules/filtered-posts-list",
  //"modules/google-map",
  //"modules/lottie",
  //"modules/newsletter",
  //"modules/pb-row-medias",
  //"modules/pb-row-oembed",
  "modules/pb-row-projects",
  "modules/pb-row-services-cloud",
  //"modules/pb-row-tabs",
  "modules/pb-row-testimonials",
  //"modules/pb-row-wrapper-reveal",
  //"modules/prefers-color-scheme-btn",
  "modules/project-archives",
  "modules/project-preview",
  //"modules/scrolling-images-sequence",
  //"modules/sharing",
  //"modules/svg-path-length",
  //"modules/text-ticker",
  "modules/video"
];
